<!-- =========================================================================================
    File Name: Maintenance.vue
    Description: Maintenance Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img src="@/assets/images/pages/confirmation.png" alt="confirmation" class="mx-auto mb-4" />
      <h1>Check your inbox.</h1>
      <br />
      <h2>Click the link we sent to {{ email }} to complete your account set-up.</h2>
      <br />
      <vs-button v-on:click="sendAccountConfirmationEmail()">Resend Email</vs-button>
      <br />
      <p><a href="/pages/register">Back to sign-up </a></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: JSON.parse(localStorage.getItem('userInfo')).email,
    }
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.$vs.loading.close()
  },
  methods: {
    async sendAccountConfirmationEmail() {
      await this.$store.dispatch('hoverlay/sendAccountConfirmationEmail', { email: this.email, redirect_to: 'spaces' })
      this.$vs.notify({
        title: 'Success',
        text: 'Email has been sent',
        iconPack: 'feather',
        icon: 'icon-success-circle',
        color: 'success',
      })
    },
  },
}
</script>
